import {combineReducers} from 'redux';

import authReducer from './auth/auth.reducer';
import appDataReducer from './appStore/appStore.reducer';

const initialState = {
  sidebarShow: true,
}

const sideBarReducer = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const rootReducer = combineReducers({
  auth: authReducer,
  appData: appDataReducer,
  sideBarReducer
});

export default rootReducer;
