import {HTTPClient} from './http.service';

export const authService = {
  async login(data) {
    try {
      const {
        data: {data: userData}
      } = await HTTPClient.post('/auth/signin', {
        ...data,
        deviceId: new Date()
      });
      return userData;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async forgotPass_sendEmail(email) {
    try {
      return await HTTPClient.post('/auth/forgotPassword', {email});
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async signout() {
    try {
      return await HTTPClient.post('/auth/signout');
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async resetPassword(data) {
    try {
      return await HTTPClient.post('/auth/resetPassword', {...data});
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async registerUser(data) {
    try {
      return await HTTPClient.post('/auth/signup', {...data});
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async createPassword(data) {
    try {
      return await HTTPClient.post('/auth/signup', {...data});
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async getUser(id) {
    try {
      return await HTTPClient.get(`/profile/${id}`, {...data});
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async checkLinkExp(data) {
    try {
      return await HTTPClient.get('/auth/verifyCode', {...data});
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async checkValidation(data) {
    try {
      return await HTTPClient.get('/verifyEmail', {...data});
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async createPassword(data) {
    try {
      return await HTTPClient.post('/updatePassword', {...data});
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async updateUser(data) {
    try {
      return await HTTPClient.customRequest({
        url: '/profile/updateUser',
        method: "put",
        headers: {'Content-Type': 'multipart/form-data'},
        data
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }
};
