import {call, all} from 'redux-saga/effects';
import {authSaga} from './auth/auth.saga';
import {appStoreSaga} from './appStore/appStore.saga';

export default function* RootSaga() {
  yield all([
    call(authSaga),
    call(appStoreSaga)
  ])
};
