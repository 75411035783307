import React from 'react';
import DivineLogo from '../../assets/divine.png';
import './styles.scss';

export default () => (
  <div className="pt-3 text-center page-loader">
    <img src={DivineLogo} width='100px' />
    <div className="sk-spinner sk-spinner-pulse">Loading....</div>
  </div>
);
