import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {actionTypes} from './auth.actionTypes.js'

const INIT_STATE = {
  authToken: undefined,
  loginProcess: false,
  loginError: null,
  frgtpassEmailSent: false,
  frgtpassEmailError: null,
  apiProcesss: false
}

const authReducer = persistReducer(
  {storage, key: 'turbo-auth', whiteList:['user', 'authToken', 'userMall']},
  (state=INIT_STATE, {type, payload}) => {
    switch (type) {
      case actionTypes.LOGOUT_INIT:
        return INIT_STATE
      case actionTypes.LOGIN_INIT:
        return {
          ...state,
          loginProcess: true
        }
      case actionTypes.LOGIN_SUCCESS:
        return {
          ...state,
          loginProcess: false,
          authToken: payload.data.token,
          userData: payload.data
        }
      case actionTypes.LOGIN_FAIL:
        return {
          ...state,
          loginProcess: false,
          loginError: payload
        }
      case actionTypes.FRGTPASS_INIT:
        return {
          ...state,
          apiProcesss: true
        }
      case actionTypes.FRGTPASS_SUCCESS:
        return {
          ...state,
          apiProcesss: false,
          frgtpassEmailSent: true
        }
      case actionTypes.FRGTPASS_FAIL:
        return {
          ...state,
          apiProcesss: false,
          frgtpassEmailError: true
        }
      case actionTypes.REGISTER_INIT:
        return {
          ...state,
          apiProcesss: true,

        }
      case actionTypes.REGISTER_SUCCESS:
        return {
          ...state,
          apiProcesss: false,

        }
      case actionTypes.REGISTER_FAIL:
        return {
          ...state,
          apiProcesss: false,

        }

        break;
      default:
        return state;
    }
  }
)

export default authReducer;
