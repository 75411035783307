import {takeLatest, put, call, all, takeEvery} from 'redux-saga/effects';
import {actionTypes} from './auth.actionTypes';
import {loginSuccess, loginFail} from './auth.actions';
import {authService} from '../../services/auth.service';
import {HTTPClient} from '../../services/http.service';
import {logoutAction} from './auth.actions';
import Swal from 'sweetalert2';

export function* handleLogin({payload: {username, password}}) {
  try {
      const userData = yield authService.login({username, password});
      HTTPClient.saveHeader({key: 'Authorization', value: `Bearer ${userData.token}`});
      yield all([
        put(loginSuccess(userData))
      ])
  } catch (e) {
    yield put(loginFail(e?.data?.status || 'Login Failed'));
    Swal.fire('', 'Login failed', 'error');
  }
}

export function* loginSaga() {
  yield takeEvery(actionTypes.LOGIN_INIT, handleLogin);
}

export function* handleLogout() {
  // yield put(logoutAction());
  // yield put(appConstOnLogout());
  // yield put(onUserLogout());
  const userData = yield authService.signout();
  yield put(logoutAction())
  yield HTTPClient.deleteHeader('Authorization');
  yield HTTPClient.deleteHeader('mallId');
}

export function* logoutSaga() {
  yield takeLatest(actionTypes.LOGOUT_INIT, handleLogout);
}

export function* handleFrgtPassSendEmail({payload: email}) {
  try {
    const response = yield authService.forgotPass_sendEmail(email);
  } catch (e) {
    console.log(e);
  }
}

export function* frgtPassEmailSend() {
  yield takeLatest(actionTypes.FRGTPASS_INIT, handleFrgtPassSendEmail);
}

export function* frgtPassSaga() {
  yield all([
    call(frgtPassEmailSend)
  ]);
}

export function* handleRegisterUser({payload: {email, password, username}}) {
  try {
    yield authService.registerUser({
      email,
      password,
      username
    });
  } catch (e) {
    console.log(e);
  }
}

export function* registerSaga() {
  yield takeLatest(actionTypes.REGISTER_INIT, handleRegisterUser);
}

export function* authSaga() {
  yield all([
    call(loginSaga),
    call(logoutSaga),
    call(frgtPassSaga),
    call(registerSaga),
  ]);
}
