import {HTTPClient} from './http.service';

export const appService = {
  async getAppConstant() {
    try {
      const {data:{data: appConstant}} = await HTTPClient.get(`/getConstants`);
      return appConstant;
    }catch(e) {
      return Promise.reject(e)
    }
  },
  async getMenuObj () {
    try {
      const {data:{data: menuObj}} = await HTTPClient.get('/modules/getMenuObject');
      return menuObj;
    }catch(e) {
      return Promise.reject(e.data.status);
    }
  },
  async getModulesList () {
    try {
      const {data:{data: allModules}} = await HTTPClient.get('/modules/getModules');
      return allModules;
    }catch(e) {
      return Promise.reject(e.data.status);
    }
  },
  async getMallsList () {
    try {
      const {data:{data: docs}} = await HTTPClient.get('/mall/');
      return docs;
    }catch(e) {
      return Promise.reject(e.data.status);
    }
  },
  async getNotifications() {
    try {
      const {data:{data: docs}} = await HTTPClient.get('/getFollowupList');
      return docs;
    }catch(e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
  async getNewNotification() {
    try {
      const {data:{data: docs}} = await HTTPClient.get(`/getFollowupList`, {currentTime: Date.now()});
      return docs;
    }catch(e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
}
