import {actionTypes} from './auth.actionTypes';

export const logoutStart = (data) => ({
  type: actionTypes.LOGOUT_INIT,
  payload: data
});

export const logoutAction = () => ({
  type: actionTypes.LOGOUT_ACTION,
  // payload: data
})

export const loginStart = (data) => ({
  type: actionTypes.LOGIN_INIT,
  payload: data
});

export const loginSuccess = (data) => ({
  type: actionTypes.LOGIN_SUCCESS,
  payload: {data}
});

export const loginFail = (error) => ({
  type: actionTypes.LOGIN_FAIL,
  payload: error
});

export const frgtPassInit = (data) => ({
  type: actionTypes.FRGTPASS_INIT,
  payload: data
});

export const frgtPassSuccess = () => ({
  type: actionTypes.FRGTPASS_SUCCESS,
});

export const frgtpassFail = (error) => ({
  type: actionTypes.FRGTPASS_FAIL,
  payload: error
});

export const registerStart = (data) => ({
  type: actionTypes.REGISTER_INIT,
  payload: data
});

export const registerSuccess = () => ({
  type: actionTypes.REGISTER_SUCCESS,
});

export const registerFail = () => ({
  type: actionTypes.REGISTER_FAIL,
});
