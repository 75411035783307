import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {store} from '../store';
import {logoutStart} from '../store/auth/auth.actions';

const axiosInstance = axios.create({
  baseURL: "http://20.193.154.99:8080/api/v1"
});

const instanceCreator = ($axios) => ({
  saveHeader({key, value}) {
    $axios.defaults.headers.common[key] = value;
  },
  deleteHeader(key) {
    delete $axios.defaults.headers.common[key];
  },
  get(url, params) {
    return $axios.get(url, {params});
  },
  post(url, data) {
    return $axios.post(url, data);
  },
  put(url, data) {
    return $axios.put(url, data);
  },
  delete(url, data={}) {
    return $axios.delete(url, data);
  },
  deleteWithBody(url, data) {
    return $axios.delete(url, {data});
  },
  customRequest(config) {
    return $axios(config);
  },
  successHandler(response) {
    return response;
  },
  errorHandler(error) {
    const {response} = error;
    const {data: {status}} = response;
    if(status.code === 401 && status.message === "Token Expired") {
      store.dispatch(logoutStart());
    }
    withReactContent(Swal).fire(stats.message, "", "info");
    return Promise.reject(response);
  },
  interceptorRef: null,
  mountResponseInterceptor() {
    this.interceptorRef = $axios?.interceptorRef?.response.use(
      this.successHandler,
      this.errorHandler
    );
  },
  ejectResponseInterceptor() {
    $axios.interceptorRef.response.eject(this.interceptorRef);
  }
});

export const HTTPClient = instanceCreator(axiosInstance);
HTTPClient.mountResponseInterceptor();
