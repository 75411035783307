import {applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import {persistStore} from 'redux-persist';
import {HTTPClient} from '../services/http.service';
import {appConst_init} from './appStore/appStore.action';

import RootReducer from './reducers';
import RootSaga from './saga';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];


export const store = createStore(
	RootReducer,
	composeEnhancers(applyMiddleware(...middleware))
);

export const persistor = () => persistStore(store,{}, () => {
  const {auth} = store.getState();
  if(auth.authToken) {
		HTTPClient.saveHeader({key: 'Authorization', value: `Bearer ${auth.authToken}`});
	}
  store.dispatch(appConst_init());
  // store.dispatch(modules_init());
});

sagaMiddleware.run(RootSaga);
