export const actionTypes = {
  LOGIN_INIT: 'LOGIN_INIT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGOUT_INIT: 'LOGOUT_INIT',
  FRGTPASS_INIT: 'FRGTPASS_INIT',
  FRGTPASS_SUCCESS: 'FRGTPASS_SUCCESS',
  FRGTPASS_FAIL: 'FRGTPASS_FAIL',
  REGISTER_INIT: 'REGISTER_INIT',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAIL: 'REGISTER_FAIL',
  LOGOUT_ACTION: 'LOGOUT_ACTION',
};
