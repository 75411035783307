import React, {useEffect, Suspense} from "react";
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch, connect} from "react-redux";
import {ToastContainer, toast} from 'react-toastify';
import {appService} from './services/app.service';
import 'react-toastify/dist/ReactToastify.css';

import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function checkNotificationPromise() {
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }

  return true;
}

function askNotificationPermission() {
  // function to actually ask the permissions
  function handlePermission(permission) {
    // set the button to shown or hidden, depending on what the user answers
    // notificationBtn.style.display =
    //   Notification.permission === 'granted' ? 'none' : 'block';
  }

  // Let's check if the browser supports notifications
  if (!('Notification' in window)) {
    console.log("This browser does not support notifications.");
  } else if (checkNotificationPromise()) {
    Notification.requestPermission().then((permission) => {
      handlePermission(permission);
    });
  } else {
    Notification.requestPermission((permission) => {
      handlePermission(permission);
    });
  }
}

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const DocumentationForm = React.lazy(() => import('./pages/leadDetails/components/documentationForm'))
const AgreementForm = React.lazy(() => import('./pages/leadDetails/components/agreementFormV2'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const AuthPage = () => {
  return (
    <Switch>
      <Route path='/lead-documentation' component={DocumentationForm} />
      <Route path='/lead-agreement' component={AgreementForm} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/register-user' component={Register} />
      {/* <Route path="*" component={() => <Redirect to='/login' />}/> */}
    </Switch>
  )
}

// <Route exact path='/register' component={Register} />
const App = () => {
  const dispatch = useDispatch();
  // routerHelpers.saveLastLocation(lastLocation);
  const {
    isAuthorized, userLastLocation, //menuConfig
  } = useSelector(
    (state) => {
      const { auth, urls, user,  appData} = state;
      return {
        // menuConfig,
        isAuthorized: auth.authToken !== undefined,
        userLastLocation: '/'
      } /* routerHelpers.getLastLocation() */
    },
    shallowEqual
  );

  const handleNotifications = async () => {
    const result = await appService.getNotifications();
    result.docs.map(item => {
      new Notification(`Follow Up Meeting after a minute`, {body: `You have a follow up meeting with ${item.lead.firstName}`})
    });
  }

  React.useEffect(() => {
    try {
      askNotificationPermission();
      if(Notification.permission==='granted') {
        // setInterval(() => {
          handleNotifications()
        // }, 60*1000);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <>
      <BrowserRouter basename={'/'}>
        <Suspense fallback={loading}>
          <Switch>
            {!isAuthorized ? (
              /* Render auth page when user at `/auth` and not authorized. */
              <AuthPage />
            ) : (
              /* Otherwise redirect to root page (`/`) */
              <Redirect from='/login' to={userLastLocation} />
            )}

            <Route path='/error' component={() => <h1>Error</h1>} />
            <Route path='/logout' component={Login} />

            {!isAuthorized ? (
              /* Redirect to `/auth` when user is not authorized */
              <Redirect to='/login' />
            ) : (
              <Route path="*" component={DefaultLayout} />
            )}
          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
};

const mapStateToProps = () => ({
  // apiErrors: appData.apiErrors,
  // toastMessage: appData.toastObj
})

export default connect(mapStateToProps)(App);
